@use 'sass:color';

@use './_colors.scss';
@use './dimensions';
@use '@material/theme' with (
  $primary: colors.$primary,
  $secondary: colors.$secondary,
  $background: colors.$background
);

@use 'raincoat/css/_raincoat-base.scss';

@use '@material/banner';
@use '@material/banner/styles';
@use '@material/button/mdc-button';
@use '@material/button';
@use '@material/card';
@use '@material/checkbox/mdc-checkbox';
@use '@material/data-table/data-table';
@use '@material/dialog';
@use '@material/drawer';
@use '@material/fab';
@use '@material/form-field/_index.scss' as form-field;
@use '@material/form-field/mdc-form-field';
@use '@material/icon-button';
@use '@material/icon-button/styles' as icon-button-styles;
@use '@material/image-list';
@use '@material/image-list/mdc-image-list';
@use '@material/linear-progress';
@use '@material/list/mdc-list';
@use '@material/menu-surface/mdc-menu-surface';
@use '@material/menu/mdc-menu';
@use '@material/radio';
@use '@material/radio/styles' as radio-styles;
@use '@material/radio/radio-theme' as radio-theme;
@use '@material/slider/styles' as slider-styles;
@use '@material/select/mdc-select';
@use '@material/select/styles' as selectStyles;
@use '@material/tab-bar/mdc-tab-bar';
@use '@material/tab-scroller/mdc-tab-scroller';
@use '@material/tab-indicator/mdc-tab-indicator';
@use '@material/tab/mdc-tab';
@use '@material/textfield/icon';
@use '@material/textfield/mdc-text-field';
@use '@material/tooltip/styles' as tooltip-styles;
@use '@material/top-app-bar/mdc-top-app-bar';
@use '@material/typography';
@use '@material/typography/mdc-typography';

@include card.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;
@include dialog.core-styles;
@include drawer.core-styles;
@include drawer.dismissible-core-styles;
@include drawer.modal-core-styles;
@include fab.core-styles;
@include form-field.core-styles;
@include linear-progress.core-styles;

.mdc-top-app-bar {
  max-width: 100vw;
  height: 72px;
  padding-top: 6px;
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 72px;
}

body {
  background-color: theme.$background;
}

#body-content {
  max-width: 70%;
  margin: auto;
  padding: 2em;
}

.language-button {
  background-color: theme.$secondary !important;

  .mdc-button__label {
    color: white;
  }
}

.quick-exit {
  background-color: colors.$exit !important;

  .mdc-button__label {
    color: white;
  }
}

a {
  color: theme.$secondary;
}

.mdc-delete-btn {
  @include button.filled-accessible(colors.$danger);
}

.mdc-button {
  font-weight: bold;
}

.oversized-button {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.rc-pagination {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  nav li::after {
    content: none !important;
  }

  .rc-pagination-list {
    display: flex;
    list-style: none;
    padding-left: 0 !important;

    .page {
      width: 24px;
      text-align: center;
      align-self: center;
    }

    .rc-page-item {
      color: #717171;
      font-size: 20px;
      text-align: center;
      width: 48px;
      align-self: center;

      .fas {
        vertical-align: text-top;
      }
    }
  }
}

.hide {
  display: none !important;
}

@media (max-width: dimensions.$tablet-width-max) {
  #body-content {
    max-width: 100%;
  }
}

.rating {
  .mdc-form-field {
    display: inline-block;

    .mdc-radio {
      position: absolute;
      left: -5000px;
    }
  }

  .fas.fa-star {
    color: goldenrod;
  }
}

// cards
.mdc-card {
  @include card.outline(colors.$primary, 2px);
}
